import { differenceInMinutes } from "date-fns";

export const DELIVERY_STATUS_0 = "DELIVERY_STATUS_RECEIPT";
export const DELIVERY_STATUS_1 = "DELIVERY_STATUS_PICKUP_SCHEDULED";
export const DELIVERY_STATUS_2 = "DELIVERY_STATUS_PICKUP_COMPLETED";
export const DELIVERY_STATUS_3 = "DELIVERY_STATUS_WAREHOUSED";
export const DELIVERY_STATUS_4 = "DELIVERY_STATUS_DELIVERY_STARTED";
export const DELIVERY_STATUS_5 = "DELIVERY_STATUS_DELIVERED";
export const DELIVERY_STATUS_6 = "DELIVERY_STATUS_DELIVERY_SENT_BACK";
export const DELIVERY_STATUS_7 = "DELIVERY_STATUS_DELIVERY_LOST";
export const DELIVERY_STATUS_8 = "DELIVERY_RIDER_ALLOCATED";
export const DELIVERY_STATUS_9 = "ADDRESS_NOT_SUPPORTED";
export const DELIVERY_STATUS_10 = "ACCIDENT";
export const DELIVERY_STATUS_11 = "CANCELED";

export class Delivery {
  accidentAt = null;
  canceledAt = null;
  bookId = "";
  corpUser = null;
  deliveryCompletedDate = null;
  hasReview = false;
  receiverName = null;
  releasedAt = null;
  rider = null;
  senderName = null;
  status = DELIVERY_STATUS_0;

  constructor(delivery) {
    this.bookId = delivery.bookId;

    switch (Number(delivery.status)) {
      case 0:
        this.status = DELIVERY_STATUS_0;
        break;
      case 1:
        this.status = DELIVERY_STATUS_1;
        break;
      case 2:
        this.status = DELIVERY_STATUS_2;
        break;
      case 3:
        this.status = DELIVERY_STATUS_3;
        break;
      case 4:
        this.status = DELIVERY_STATUS_4;
        break;
      case 5:
        this.status = DELIVERY_STATUS_5;
        break;
      case 6:
        this.status = DELIVERY_STATUS_6;
        break;
      case 7:
        this.status = DELIVERY_STATUS_7;
        break;
      case 8:
        this.status = DELIVERY_STATUS_8;
        break;
      case 10:
        this.status = DELIVERY_STATUS_10;
        break;
      case 11:
        this.status = DELIVERY_STATUS_11;
        break;
      default:
        throw new Error("잘못된 배송상태값입니다.");
    }

    this.accidentAt = delivery.accidentAt;
    this.canceledAt = delivery.canceledAt;
    this.corpUser = delivery.corpUser;
    this.deliveryCompletedDate = delivery.deliveryCompletedDate;
    this.hasReview = delivery.hasReview;
    this.receiverName = delivery.receiverName;
    this.releasedAt = delivery.releasedAt;
    this.rider = delivery.rider;
    this.senderName = delivery.senderName;
  }

  get displayReceiverName() {
    if (this.receiverName && this.receiverName.length) {
      let first = this.receiverName.substring(0, 1);
      let encoded = []
      for (let i = 1; i < this.receiverName.length; i++) {
        encoded.push('*')
      }

      return first + encoded.join('')
    } else {
      return "";
    }
  }

  get displaySenderName() {
    return this.corpUser ? this.corpUser.title : this.senderName;
  };

  get displayTime() {
    let time = 0;

    if (this.releasedAt && this.deliveryCompletedDate) {
      time = differenceInMinutes(new Date(this.deliveryCompletedDate), new Date(this.releasedAt));
    }

    return `${parseInt(time / 60)}시간 ${time % 60}분`
  }

  get errorStatusMessage() {
    const elapsedDate = this.deliveryCompletedDate ? differenceInMinutes(new Date(), new Date(this.deliveryCompletedDate)) / 1440 : 0

    if (this.accidentAt) {
      return "해당 접수건은 사고 상태로 칭찬&응원을 작성하실 수 없습니다. 두발히어로 혹은 구매처로 문의 부탁드립니다. 감사합니다."
    } else if (this.canceledAt) {
      return "해당 접수건은 취소 상태로 칭찬&응원을 작성하실 수 없습니다. 두발히어로 혹은 구매처로 문의 부탁드립니다. 감사합니다."
    } else if (this.status !== DELIVERY_STATUS_5 || !this.deliveryCompletedDate) {
      return "해당 접수건은 배송완료 상태가 아닙니다. 두발히어로 혹은 구매처로 문의 부탁드립니다. 감사합니다."
    } else if (this.hasReview) {
      return "해당 배송건에 대해서 이미 칭찬&응원을 등록하셨습니다. 감사합니다."
    } else if (elapsedDate > 3) {
      return "배송리뷰 등록 기간이 지나 이용하실 수 없습니다. 더 빠르고 안전하게 배송하겠습니다."
    } else {
      return null;
    }
  };

  get riderName() {
    return this.rider ? this.rider.name : '';
  }
}
