import React, { createContext, useCallback, useContext, useReducer } from "react";

import { Delivery } from "../../Models";

import api from "../Api";

export const CONSTANTS = {
  FETCH_BY_TOKEN: "FETCH_BY_TOKEN",
  RESET_BODY: "RESET_BODY",
  SET_BODY: "SET_BODY",
};

const INITIAL_STATE = {
  delivery: {},
  body: {
    star: 5,
    comment: "두발히어로 빠른 배송 응원합니다.",
    terms: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_BY_TOKEN:    
    return {
      ...state,
      delivery: action.delivery,
    };
    case CONSTANTS.RESET_BODY:      
      return {
        ...state,
        body: INITIAL_STATE.body,
      };
    case CONSTANTS.SET_BODY:
      return {
        ...state,
        body: {
          ...state.body,
          ...action.body,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveryStore = () => {
  const { dispatch, state } = useContext(Context);

  const createReview = useCallback(
    async (bookId, data) => await api.put(`/public/deliveries/${bookId}/reviews`, data),
    [api.put]
  );

  const fetchByToken = useCallback(async ({ token }) => {
    const delivery = await api.get(`/public/deliveries/reviews/${token}`);

    dispatch({ 
      type: CONSTANTS.FETCH_BY_TOKEN, 
      delivery: new Delivery(delivery),
    });
  }, [api.get, dispatch]);

  const resetBody = useCallback(
    () => {      
      dispatch({ type: CONSTANTS.RESET_BODY });
    },
    [dispatch]
  );

  const setBody = useCallback(
    (body = {}) => {      
      dispatch({ type: CONSTANTS.SET_BODY, body });
    },
    [dispatch]
  );

  return {
    state,
    createReview,
    fetchByToken,
    resetBody,
    setBody,
  };
};
