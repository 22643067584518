import React, { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import { withQueryParams, StringParam } from 'use-query-params';

import { useDeliveryStore, useModalStore, useNavStore } from "../stores/hooks";

import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import PrivacyModal from "../modals/PrivacyModal";

const BodyContent = styled.div`
  overflow: hidden;
  position: relative;
  min-height: 100vh;
  background-color: #202020;
  color: #fff;

  ${({ navOpen }) =>
    navOpen && `
      position: fixed;
      width: 100%;
      height: 100vh;
    `}
`;

const BgTriangle = styled.div`
  width: 100%;
  height: 180px;
  background-image: linear-gradient(
    90deg,
    rgba(234, 40, 69, 1) 0%,
    rgba(243, 71, 78, 1) 100%
  );
  position: absolute;
  left: 0;
  top: 0;

  &:before {
    content: "";
    border-top: 40px solid transparent;
    border-right: 100vw solid #5e297a;
    position: absolute;
    left: 0;
    top: 140px;
  }
`;

const BgTrianglePurple = styled.div`
  width: 100%;
  height: 100px;
  background-image: linear-gradient(180deg, #5e297a, #202020);
  position: absolute;
  left: 0;
  top: 180px;
`;


const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 799px;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
`;

const SectionTitle = styled.div`
  padding-bottom: 20px;
`;
const PreTitle = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;
const Title = styled.h2`
  font-size: 32px;
  font-weight: normal;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
`;

const SectionEvent = styled.div`
  padding-top: 10px;
`
const EventPreTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`
const EventTitle = styled.h4`
  font-size: 28px;
  font-weight: normal;
  margin: 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #545454;
`
const ImageEvent = styled.img`
  height: 83px;
  position: absolute;
  right: 16px;
  bottom: 21px;
`

const SectionBody = styled.div``
const SectionIntro = styled.div`
  padding-top: 16px;
  padding-bottom: 22px;
`
const Paragraph = styled.div`
  margin-bottom: 32px;
  display: grid;
  row-gap: 18px;
`
const IntroDesc = styled.p`
  font-size: 16px;
  text-align: left;
  margin: 0;
`

const Highlight = styled.span`
  color: #75ff6f;
`

const SectionRider = styled.div`
  border-top: 1px solid #545454;
  padding-top: 16px;
  padding-bottom: 31px;
`;
const RiderLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 29px;
`
const RiderWrap = styled.div`
  display: flex;
  align-items: center;
  
`
const RidierIcon = styled.div`
  width: 36px;
  height: 36px;
  background-color: #fff;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/images/rider.svg);
  border-radius: 50%;
  margin-right: 18px;
`
const RiderContent = styled.div`
  flex: 1;
  word-break: break-all;
`;
const RiderName = styled.div`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 14px;  
`
const RiderComment = styled.div`
  font-size: 12px;
  color: #75ff6f;
`



const SectionReview = styled.div`
  padding-bottom: 25px;
`

const ReviewLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
`

const ReivewStars = styled.div`
  padding-bottom: 16px;
  height: 91px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Star = styled.div`
  width: 42px;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/images/star3.png);
  cursor: pointer;
  margin: 0 8px;

  ${(props) => props.active && `
    background-image: url(/assets/images/star1.png);
  `}
`

const ReviewTextarea = styled.textarea`
  resize: none;
  height: 200px;
  background-color: #333;
  padding: 15px 10px;
  width: 100%;
  border: none;
  color: #fff;
  font-size: 16px;
  margin-bottom: 8px;
  
  &::placeholder { 
    color: #999;
  }
`

const ReviewCheckbox = styled.div`
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1;
`

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(/assets/images/radio-off.png);
  margin-right: 10px;

  ${(props) => props.active && `
    background-image: url(/assets/images/radio-on.png);
  `}
`

const CheckboxLabel = styled.span``

const ReviewSubmit = styled.button`
  width: 100%;
  height: 50px;
  background-color: #75ff6f;
  font-size: 16px;
  font-weight: bold;
  color: #202020;
  border: none;
`

const SectionBanner = styled.div`
  padding-top: 24px;
`;

function Index({ query }) {
  const { token } = query;

  const { body, delivery } = useDeliveryStore().state;
  const { ...actions } = useDeliveryStore();

  const { openModal } = useModalStore();
  const { modals } = useModalStore().state;

  const { navActive } = useNavStore().state;

  useEffect(() => {
    fetchByToken();
  }, []);

  useEffect(() => {
    if (delivery.errorStatusMessage) {
      alert(delivery.errorStatusMessage)
    }
  }, [delivery]);

  const fetchByToken = useCallback(async () => {
    if (token) {
      try {
        await actions.fetchByToken({ token });
      } catch(e) {
        if (e.status > 500) {
          alert('배송정보를 가져오는데 실패했습니다. 다시 시도해주세요.')
        } else {
          alert('배송정보를 가져올 수 없습니다. 알림톡으로 전달받은 링크를 다시 확인해주세요.')
        } 
      }
    } else {
      alert('잘못된 접근입니다. 알림톡으로 전달받은 링크를 다시 확인해주세요.')
    }
  }, [actions.fetchByToken, token]);

  const handleChangeStar = useCallback((star) => {
    actions.setBody({ star });
  }, [actions.setBody]);

  const handleChange = useCallback((e) => {
    if (e.target.name === 'comment' && e.target.value) {
      const korean = /[ㄱ-힣]/g;
      const pickupLocationText = e.target.value;

      const textKorean = pickupLocationText.match(korean);

      if (pickupLocationText.length + (textKorean ? textKorean.length : 0) <= 1000) {
        actions.setBody({
          [e.target.name]: e.target.value, 
        })
      } else {
        alert('한글 500자 또는 영문 1000자로 입력해주세요.')
      }
    } else {
      actions.setBody({
        [e.target.name]: e.target.value, 
      })
    }
  }, [actions.setBody]);

  const handleToggleTerms = useCallback(() => {
    actions.setBody({ 
      terms: !body.terms,
    });
  }, [actions.setBody, body.terms]);

  const handleChangeTerms = useCallback((checked) => {
    actions.setBody({ 
      terms: checked,
    });
  }, [actions.setBody]);

  const handleSubmit = useCallback(async () => {
    let _body = { ...body };

    if (!token || !delivery.bookId) {
      alert('잘못된 접근입니다. 알림톡으로 전달받은 링크를 다시 확인해주세요.')
    } else if (!_body.comment) {
      alert('남기실 말씀을 작성해주세요.')
    } else if (!_body.terms) {
      alert('개인정보 수집 및 이용 동의에 체크해주세요.')
    }
     else if (delivery.errorStatusMessage) {
      alert(delivery.errorStatusMessage)
    } else {
      try {
        let data = {
          token,
          star: _body.star,
          text: _body.comment,
        }
  
        await actions.createReview(delivery.bookId, data);
  
        alert('두발히어로 당일배송 칭찬&응원 감사합니다. 더 빠르고 정확한 배송서비스 만들어가겠습니다. (경품 응모 완료)');
  
        actions.resetBody();
      } catch(e) {
        if (e.message) {
          alert(e.message)
        } else {
          alert('칭찬&응원 작성에 실패했습니다. 다시 시도해주세요.')
        }
      }
    }
  }, [
    actions.createReview, 
    actions.resetBody,
    body, 
    delivery,
    token,
  ]);

  const handelClickPrivacyModal = useCallback(() => {
    openModal(
      <PrivacyModal key="privacy-modal" onCheck={handleChangeTerms} />
    );
  }, [openModal]);

  return (
    <BodyContent navOpen={navActive}>
      <BgTriangle />
      <BgTrianglePurple />
      <Nav />
      <SectionTitle>
        <Container>
          <PreTitle>4시간 오늘도착 배송혁명</PreTitle>
          <Title>
            두발히어로<br/>
            칭찬&#38;응원
          </Title>
        </Container>
      </SectionTitle>
      <SectionEvent>
        <Container>
          <EventPreTitle>
            배송기사님<br/>
            칭찬과 응원을 하면!!
          </EventPreTitle>
          <EventTitle>선물이 쏟아진다!</EventTitle>
          <ImageEvent src="/assets/images/event.svg" alt="이벤트 아이콘" />
        </Container>
      </SectionEvent>
      <SectionBody>
        <Container>
          <SectionIntro>
            <Paragraph>
              <IntroDesc>
                {delivery.displayReceiverName}님, <Highlight>{delivery.displaySenderName}</Highlight>에서 발송한 접수번호 <Highlight>{delivery.bookId}</Highlight> 물품이 물류센터에서 <Highlight>출차 후 {delivery.displayTime}</Highlight>만에 도착했어요. 구매물품은 잘 받으셨나요?
              </IntroDesc>
              <IntroDesc>
                오늘도착 택배서비스 두발히어로를 통해서 구매물품을 받아보신 경험과, 두발히어로 배송기사님에 대해서 솔직한 평가 부탁드려요. 고객님의 칭찬과 개선의견, 불만사항을 참고하여 매일 이용하고 싶은 두발히어로 배송서비스로 만들어가겠습니다.
              </IntroDesc>
              <IntroDesc>
                칭찬&#38;응원 남겨주시면 이벤트에 자동 응모되오니 많이 참여해주세요.
              </IntroDesc>
            </Paragraph>
            <Paragraph>
              <IntroDesc>
                <Highlight>[Event1] 칭찬&#38;응원 등록 감사 선물 이벤트</Highlight><br/>
                매월 칭찬&#38;응원 등록하신 고객님들 중 10분을 추첨하여 감사 선물을 드리고 있어요. 많이 참여해주세요!
              </IntroDesc>
            </Paragraph>
            <Paragraph>
              <IntroDesc>
                <Highlight>[Event2] 블로그 리뷰 등록 이벤트</Highlight><br/>                
                두발히어로 배송 경험에 대해서 블로그 리뷰를 남겨주실 고객님을 찾고 있어요. 블로그 리뷰어로 선정되어 리뷰를 남겨주시면 2만원 상당의 외식상품권을 선물로 드려요. 많이 참여해주세요!
              </IntroDesc>
            </Paragraph>
            <Paragraph>
              <IntroDesc>
                <Highlight>블로그 리뷰어 신청방법 안내</Highlight><br/>                
                칭찬&#38;응원 남길 말씀 입력란 끝에 "블로그 리뷰 신청합니다. 본인 블로그 주소 url"을 남겨주세요. 담당자 확인 후 연락드릴게요.
              </IntroDesc>
            </Paragraph>
            
          </SectionIntro>
          <SectionRider>
            <RiderLabel>배송 히어로</RiderLabel>
            <RiderWrap>
              <RidierIcon />
              <RiderContent>
                <RiderName>{delivery.riderName}</RiderName>
                <RiderComment>“신속하고 정확하게 배송하겠습니다.”</RiderComment>
              </RiderContent>
            </RiderWrap>
            
          </SectionRider>
          <SectionReview>
            <ReviewLabel>별점주기</ReviewLabel>
            <ReivewStars>
              <Star active={body.star > 0} onClick={() => handleChangeStar(1)} />
              <Star active={body.star > 1} onClick={() => handleChangeStar(2)} />
              <Star active={body.star > 2} onClick={() => handleChangeStar(3)} />
              <Star active={body.star > 3} onClick={() => handleChangeStar(4)} />
              <Star active={body.star > 4} onClick={() => handleChangeStar(5)} />
            </ReivewStars>
            <ReviewLabel>남길말씀</ReviewLabel>
            <ReviewTextarea 
              onChange={handleChange}
              value={body.comment}
              name="comment"
              placeholder="내용을 입력해주세요."
            />
            <ReviewCheckbox>
              <Checkbox active={body.terms} onClick={handleToggleTerms} />
              <CheckboxLabel onClick={handelClickPrivacyModal}>
                개인정보 수집 및 이용 동의(필수)
              </CheckboxLabel>
            </ReviewCheckbox>
            <ReviewSubmit type="button" onClick={handleSubmit}>칭찬&#38;응원 등록하기</ReviewSubmit>
          </SectionReview>
        </Container>
      </SectionBody>
      <SectionBanner>
        <Container>
          <Banner />
        </Container>
      </SectionBanner>
      <Footer />

      {modals.map((modal) => modal)}
    </BodyContent>
  );
};

export default withQueryParams({ token: StringParam }, Index);
