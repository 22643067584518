import React from 'react';
import dotenv from "dotenv"
import ReactDOM from 'react-dom';
import AppRoutes from "./AppRoutes";
import reportWebVitals from './reportWebVitals';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./App.css"

dotenv.config()

ReactDOM.render(<AppRoutes />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();