import React, { memo } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";
import Slider from "react-slick";

const BannerWrap = styled.div`
  font-size: 0;
  margin-bottom: 16px;
  margin-left: -16px;
  margin-right: -16px;
`;
const BannerImage = styled.img`
  width: 100%;
`;
const BannerItem = styled.div``

const sliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
};

const adList = [
  {
    image: "/assets/images/doobalhero_freshcode.jpg",
    url: "https://www.freshcode.me/menu?utm_source=chainlogis&utm_medium=banner&utm_campaign=new_chaennel&utm_term=menu",
    alt: "관리하려고 구독한 샐러드가 이렇게 맛있으면 못참지 맛있는건 못참는 당신을 위한 정기배송, 프레시코드",
  },
  {
    image: "/assets/images/banner-cu.png",
    url: "https://blog.naver.com/secondplanteam/222969089674",
    alt: "바로택배에서 가장 저렴한 운임으로 CU편의점택배를 이용하세요!",
  },
  {
    image: "/assets/images/banner-gs.png",
    url: "https://blog.naver.com/secondplanteam/222969089674",
    alt: "GS25 편의점에서 초특가 택배비를 만나보세요.",
  },
  {
    image: "/assets/images/doobalhero_wingbling.jpg",
    url: "https://wingbling.co.kr/event/secret_link1.html",
    alt: "직장인 BEST! 데일리 포인트 14k 귀걸이 #직장인추천템 #이광고에서만",
  },
  {
    image: "/assets/images/banner-210909_1.png",
    url: "https://doobalhero.kr/main/main.html",
    alt: "두발히어로 당일도착 택배와 함께할 고객사를 추천해주세요 추천 연락 partner@chainlogis.com",
  },
  {
    image: "/assets/images/banner-210909_2.png",
    url: "https://rider.doobalhero.kr/",
    alt: "콜 경쟁 없는 오토바이 배송기사님 모집 월 500만원! 이상",
  },
  {
    image: "/assets/images/banner-210909_3.png",
    url: "https://rider.doobalhero.kr/",
    alt: "주말 인력 모집 - 오토바이 배송기사님 모집 주말만으로 200만원 가능!!",
  },
  {
    image: "/assets/images/banner-ezadmin.jpg",
    url: "https://www.ezadmin.co.kr/sub_01_76.html",
    alt: "성공하는 쇼핑몰에는 이지어드민이 있다.",
  },
];

const shuffleArr = (arr) => {
  let currentIndex = arr.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = arr[currentIndex];
    arr[currentIndex] = arr[randomIndex];
    arr[randomIndex] = temporaryValue;
  }
  return arr;
}

const Banner = memo(() => {
  return (
    <BannerWrap>
      <Slider {...sliderSettings}>
        <BannerItem>
          <Link to={{ pathname: "https://www.instagram.com/doobalhero/" }} target="_blank">
            <BannerImage src="/assets/images/banner-instagram.png" alt="두발히어로 인스타그램에서 다양한 이야기를 확인하고 다양한 이벤트에서 상품 받아가세요!" />
          </Link>
        </BannerItem>
        <BannerItem>
          <Link to={{ pathname: "http://dooshop.kr/" }} target="_blank">
            <BannerImage src="/assets/images/banner-dooshop.png" alt="당일배송 브랜드를 Dooshop에서 확인하세요." />
          </Link>
        </BannerItem>
        {
          shuffleArr(adList).map((el, idx) => {
            return (
              <BannerItem key={idx}>
                {el.url ? (
                  <Link
                    to={{
                      pathname: el.url,
                    }}
                    target="_blank"
                  >
                    <BannerImage src={el.image} alt={el.alt} />
                  </Link>
                ) : (
                  <BannerImage src={el.image} alt={el.alt} />
                )}
                
              </BannerItem>
            );
          })
        }
      </Slider>
    </BannerWrap>
  );
});

export default Banner;
