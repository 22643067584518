import React, { useCallback } from "react";
import styled from "@emotion/styled";
import Modal from "../components/Modal";

import { useModalStore } from "../stores/hooks";

const ModalLogo = styled.div`
  padding: 17px 16px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`

const LogoImg = styled.img`
  height: 40px;
`

const ModalHeader = styled.div`
  padding: 24px 24px 6px;
  border: none;
  border-bottom: 1px solid #666;
  display: flex;
  justify-content: space-between;
  align-item: center;

  @media (max-width: 768px) {
    padding: 8px 16px;
  }
`;

const ModalTitle = styled.h3`
  margin: 0;
  line-height: 1;
  font-size: 40px;
  font-weight: normal;
  color: #ffffff;
  line-height: 50px;

  @media (max-width: 768px) {
    font-size: 28px;
    line-heihgt: 35px;
  }
`;

const ModalColse = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(/assets/images/close.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-size: 0;
  cursor: pointer;

  @media (max-width: 768px) {
    display: none;
  }
`;

const ModalBody = styled.div`
  padding: 24px 24px 31px;
  color: #ccc;
  font-size: 16px;

  @media (max-width: 768px) {
    height: calc(100vh - 145px);
    overflow: auto;
    font-size: 12px;
    padding: 8px 16px 23px;
  }
`;
const ModalBodyTitle = styled.div`
  font-weight: bold;
`;
const ModalBodyHighlight = styled.div`
  color: #fff;
`;

const ModalBodyButtonWrap = styled.div`
  display: none;
  margin-left: -5px;
  margint-right: -5px;

  @media (max-width: 768px) {
    display: flex;
    margin-bottom: 32px;
  }
`
const ModalBodyButton = styled.button`
  width: 100%;
  height: 48px;
  background-color: #75ff6f;
  border: none;
  color: #202020;
  font-size: 18px;
  margin: 20px 5px 0;

  &:active,
  &:hover {
    opacity: 0.8;
  }

  
`;
const ModalBodyCancelButton = styled(ModalBodyButton)`
  background-color: #C4C4C4;
`

const ModalCopyright = styled.div`
  display: none;
  color: #999;
  font-size: 12px;
  border-top: 1px solid #666;
  padding-top: 8px;

  @media (max-width: 768px) {
    display: block;
  }
`


function PrivacyModal({ onCheck }) {
  const { ...actions } = useModalStore();

  const closeModal = useCallback(() => {
    actions.closeModal();
  }, [actions.closeModal]);

  const confirm = useCallback((checked) => {
    onCheck(checked);
    closeModal();
  }, [])

  return (
    <Modal backdrop="static">
      <ModalLogo>
        <LogoImg
          src="/assets/images/logo.png"
          alt="두발히어로 로고"
        />
      </ModalLogo>
      <ModalHeader>
        <ModalTitle>개인정보 수집 및 이용 동의</ModalTitle>
        <ModalColse onClick={closeModal}>닫기</ModalColse>
      </ModalHeader>
      <ModalBody>
        주식회사 체인로지스는 이용자의 개인정보보호를 매우 중요시하며, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」과 「개인정보보호법」 등 개인정보 보호 법령을 준수하고자 노력하고 있습니다.
        <br />
        <br />
        주식회사 체인로지스는 배송완료 평가 및 이벤트 참여에 대한 처리를 위하여 아래의 개인정보 수집/이용하고자 하오니, 아래 내용을 상세히 읽은 후 동의 여부를 결정하여 주시기 바랍니다.
        <br />
        <br />
        <ModalBodyTitle>[1] 개인정보의 수집 및 이용 목적</ModalBodyTitle>
        배송완료 평가 및 이벤트 참여
        <br />
        <br />
        <ModalBodyTitle>[2] 수집하는 개인정보의 항목</ModalBodyTitle>
        성명, 휴대전화(연락처), 주소
        <br />
        <br />
        <ModalBodyTitle>[3] 개인정보의 보유 및 이용기간</ModalBodyTitle>
        정보 수집 및 이용 목적이 달성된 후 문의 내역관리를 위하여 문의 내용과 개인정보 입력항목에 대해서는 1년간 보유 이후 해당 정보를 파기합니다.
        <br />
        <br />
        <ModalBodyTitle>[4] 동의를 거부할 권리 및 동의 거부에 따른 불이익</ModalBodyTitle>
        서비스 제공을 위하여 기본 정보를 수집하고 있으며, 제공을 원하지 않을 경우 수집하지 않으며, 미 동의(로 인해)시 서비스 이용이 제한 됩니다.
        <br />
        <br />
        <ModalBodyHighlight>
          본인은 [개인정보 수집 및 이용에 관한 동의]를 잘 읽어 보았으며, 개인정보수집 및 이용에 동의 합니다.
        </ModalBodyHighlight>
        <ModalBodyButtonWrap>
          <ModalBodyCancelButton type="button" onClick={() => confirm(false)}>
            동의안함
          </ModalBodyCancelButton>
          <ModalBodyButton type="button" onClick={() => confirm(true)}>
            동의
          </ModalBodyButton>
        </ModalBodyButtonWrap>

        <ModalCopyright>
          두발히어로TM Copyright<br/>
          © 2018 ㈜체인로지스 All rights reserved.
        </ModalCopyright>
      </ModalBody>
    </Modal>
  );
}

export default PrivacyModal;
